import { SEO } from "gatsby-theme-core";
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@streamo/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { GoogleMap } from "gatsby-theme-ui";
import {
    PageHeader01,
    ContactUsForm01,
    ContactUsInfo02,
} from "gatsby-theme-container";

const ContactUsPage = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Contact Us" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <PageHeader01 data={content?.["page-header-section"]} />
            <ContactUsForm01 data={content?.["contact-us-form"]} />
            <div className="map relative">
                <GoogleMap data={{ map: data?.contact?.map }} />
                <ContactUsInfo02
                    data={{ contact_info: data?.contact?.contact_info }}
                />
            </div>
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query ContactUsTwoPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact02
        }
        page(title: { eq: "contact-page" }) {
            content {
                ...PageContent
            }
        }
    }
`;
ContactUsPage.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({
            contact_info: PropTypes.shape({}),
            map: PropTypes.shape({}),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ContactUsPage;
